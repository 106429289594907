
import { Vue, Options } from "vue-class-component";
import { defineAsyncComponent } from "@vue/runtime-core";
import SectionFilterDashboard from "@/app/ui/views/dashboard/component/client/section-filter-dashboard.vue";
import Refresh from "@/app/ui/components/icons/modules/refresh.vue";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
const SectionDeliveryDashboard = defineAsyncComponent(() =>
  import("@/app/ui/views/dashboard/component/client/section-delivery/index.vue")
);
const SectionCodDashboard = defineAsyncComponent(() =>
  import("@/app/ui/views/dashboard/component/client/section-cod/index.vue")
);
const SectionShippingCostDashboard = defineAsyncComponent(() =>
  import(
    "@/app/ui/views/dashboard/component/client/section-shipping-cost/index.vue"
  )
);
const SectionClaimDashboard = defineAsyncComponent(() =>
  import("@/app/ui/views/dashboard/component/client/section-claim/index.vue")
);
const SectionDeliveryPerformanceDashboard = defineAsyncComponent(() =>
  import(
    "@/app/ui/views/dashboard/component/client/section-delivery-performance/index.vue"
  )
);
@Options({
  components: {
    Refresh,
    SectionDeliveryPerformanceDashboard,
    SectionClaimDashboard,
    SectionShippingCostDashboard,
    SectionCodDashboard,
    SectionDeliveryDashboard,
    SectionFilterDashboard
  }
})
export default class DashboardClient extends Vue {
  mounted() {
    this.getLastSyncDataDashboard();
  }
  checkActiveSectionDashboard(section: string) {
    const configCorporateDashboard =
      process.env.VUE_APP_CONFIG_CORPORATE_DASHBOARD;
    if (!configCorporateDashboard) return false;
    return configCorporateDashboard.includes(section);
  }

  loadLastSyncCorporateDashboard = true;
  lastSyncCorporateDashboard = "";
  async getLastSyncDataDashboard() {
    this.loadLastSyncCorporateDashboard = true;
    const response = await DashboardController.getCorporateDashboardLastSyncData();
    this.lastSyncCorporateDashboard = response.lastUpdatedAt;
    this.loadLastSyncCorporateDashboard = false;
  }
  get formattedLastSyncDataDashboard() {
    if (!this.lastSyncCorporateDashboard) return "";
    return this.$moment(this.lastSyncCorporateDashboard).format(
      "DD MMMM YYYY, HH:mm"
    );
  }

  isShowDashboard = false;
  showDashboardPerSection: Array<boolean> = [false, false, false, false, false];
  reRenderDashboard() {
    this.isShowDashboard = false;
    this.$nextTick(() => {
      this.isShowDashboard = true;
    });
    for (const index in this.showDashboardPerSection) {
      setTimeout(() => {
        this.showDashboardPerSection[Number(index)] = true;
      }, 150 * Number(index));
    }
  }
}
