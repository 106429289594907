import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "space-y-6 overflow-hidden" }
const _hoisted_2 = {
  key: 0,
  class: "fixed flex top-0 right-0 pt-2.5 pr-2 md:p-8 text-gray-lp-800 text-10px md:text-12px font-normal italic"
}
const _hoisted_3 = {
  key: 1,
  class: "overflow-y-auto space-y-6",
  style: {"height":"calc(100vh - 165px)"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Refresh = _resolveComponent("Refresh")!
  const _component_section_filter_dashboard = _resolveComponent("section-filter-dashboard")!
  const _component_section_delivery_dashboard = _resolveComponent("section-delivery-dashboard")!
  const _component_section_cod_dashboard = _resolveComponent("section-cod-dashboard")!
  const _component_section_shipping_cost_dashboard = _resolveComponent("section-shipping-cost-dashboard")!
  const _component_section_claim_dashboard = _resolveComponent("section-claim-dashboard")!
  const _component_section_delivery_performance_dashboard = _resolveComponent("section-delivery-performance-dashboard")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.formattedLastSyncDataDashboard)
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          _createVNode(_component_Refresh, { class: "text-red-lp-300 mr-2" }),
          _createTextVNode(" Sinkronisasi Data Terakhir " + _toDisplayString(_ctx.formattedLastSyncDataDashboard), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_section_filter_dashboard, { onOnFilter: _ctx.reRenderDashboard }, null, 8, ["onOnFilter"]),
    (_ctx.isShowDashboard)
      ? (_openBlock(), _createBlock("div", _hoisted_3, [
          (
          _ctx.checkActiveSectionDashboard('delivery') && _ctx.showDashboardPerSection[0]
        )
            ? (_openBlock(), _createBlock(_component_section_delivery_dashboard, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.checkActiveSectionDashboard('cod') && _ctx.showDashboardPerSection[1])
            ? (_openBlock(), _createBlock(_component_section_cod_dashboard, { key: 1 }))
            : _createCommentVNode("", true),
          (
          _ctx.checkActiveSectionDashboard('shipping_cost') &&
            _ctx.showDashboardPerSection[2]
        )
            ? (_openBlock(), _createBlock(_component_section_shipping_cost_dashboard, { key: 2 }))
            : _createCommentVNode("", true),
          (
          _ctx.checkActiveSectionDashboard('claim') && _ctx.showDashboardPerSection[3]
        )
            ? (_openBlock(), _createBlock(_component_section_claim_dashboard, { key: 3 }))
            : _createCommentVNode("", true),
          (
          _ctx.checkActiveSectionDashboard('performance') &&
            _ctx.showDashboardPerSection[4]
        )
            ? (_openBlock(), _createBlock(_component_section_delivery_performance_dashboard, { key: 4 }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}